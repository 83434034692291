import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HasHttpRequestService {
  hasPendingRequest = signal<boolean>(false);

  startRequest(): void {
    this.hasPendingRequest.set(true);
    document.body.style.cursor = 'wait';
  }

  finalizeRequest(): void {
    this.hasPendingRequest.set(false);
    document.body.style.cursor = 'default';
  }
}
